var body = document.body;
var header = document.querySelector('.js-header');

document.addEventListener("DOMContentLoaded", function(e) {
  loadFonts();
  loadMedia();

  initNav();
  initForm();
  initService();
  initSmooth();
});

function loadFonts() {
	let wf = document.createElement('script');
	let GoogleFontsAPI = new Promise((resolve, reject) => {
		document.body.appendChild(wf);
		wf.onload = resolve;
		wf.onerror = reject;
		wf.async = true;
		wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
		wf.id = 'googlefont';
	});

	GoogleFontsAPI.then(() => {
		WebFont.load({
			google: {
			families: [
					'Roboto+Slab:700',
          'Roboto:400,400i,500,700,700i'
				]
			}
		  });
	});
}

/**
 * Loading stylesheets
 */
function loadMedia() {
  var preload, main;

  preload = document.createElement('link');
  preload.rel = 'preload';
  preload.href = '/assets/stylesheets/compiled/main.css';
  preload.as = 'style';

  main = document.createElement('link');
  main.rel = 'stylesheet';
  main.type = 'text/css';
  main.href = preload.href;
  main.media = 'all';

  // append to dom
  document.getElementsByTagName('head')[0].appendChild(preload);
  document.getElementsByTagName('head')[0].appendChild(main);

  // adding listeners
  main.addEventListener('load', function() {
    removeSplashScreen();
  });
}

function removeSplashScreen() {

	if (!body.classList.contains('js-firstload')) {
    return false;
  }
    
  setTimeout(function() {
    body.classList.remove('js-firstload');
  }, 500);
}

function initForm() {
  const forms = document.querySelectorAll('form');

  Array.from(forms).forEach((form) => {
    var hidden_fields = form.querySelector('.js-visibility');

    // show text input for checkbox « Autres »
    hidden_fields.addEventListener('change', function(event) {

      if (!('forinput' in event.target.dataset)) {
        return false;
      }

      var checkbox = event.target;
      var checkbox_input = document.querySelector(checkbox.dataset.forinput);
      var checkbox_input_container = checkbox_input.parentNode;

      if (!checkbox_input_container.classList.contains('FormElement_checkbox-input--visible')) {
        checkbox_input_container.classList.add('FormElement_checkbox-input--visible');
        checkbox_input.focus(); // TODO settimeout quand anim fini
      } else {
        checkbox_input_container.classList.remove('FormElement_checkbox-input--visible');
        checkbox_input.value = ''; // empty field
      }
    });
  });
}

function initNav() {
  var burger = header.querySelector('.js-burger');

  // scroll listener
  /*let scrollPos = 0;

  window.addEventListener('scroll', function(event) {
    if ('ontouchstart' in window) {
      // disable on mobile
      return false;
    }

    let windowY = window.scrollY;
    
    if (windowY < scrollPos) {
      // Scrolling up
      header.classList.remove('header--hidden');
    } else {
      // Scrolling down
      header.classList.add('header--hidden');
    }
    scrollPos = windowY;
  }); */

  // window resize
  window.addEventListener('resize', closeMenu, true);

  // burger click
  burger.addEventListener('click', noScroll);

  header.querySelectorAll('li a').forEach(a => {
    a.addEventListener('click', closeMenu);
  });

  // Callbacks
  function closeMenu(event) {
    // close only when it is opened
    if (header.classList.contains('header--navopen')) {
      noScroll(event);
      header.classList.remove('header--navopen');
    }
  }

  function noScroll(event) {
    if (!header.classList.contains('header--navopen')) {
      body.classList.add('no-scroll');
      header.classList.add('header--navopen');
      burger.classList.add('header__burger-button--active');
    } else {
      body.classList.remove('no-scroll');
      header.classList.remove('header--navopen');
      burger.classList.remove('header__burger-button--active');
    }
  }
}

function initService() {
  // Stacked container
  document.querySelectorAll('.services-container--stacked').forEach(container => {

    // loop checkboxes
    container.querySelectorAll('.service__checkbox').forEach(checkbox => {
      checkbox.addEventListener('change', function(event) {
        var checkbox = event.target;

        checkbox.closest('.services-container--stacked').querySelectorAll('.service__checkbox').forEach(element => {
          // uncheck other checkboxes
          if (element != checkbox) {
            element.checked = false;
          }
        });
      });
    });
  });

}

function initSmooth() {
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    });
  });
}